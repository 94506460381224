/* Component Dependencies */
var marketingTile4PackTemplate = require('templates/marketingTiles-4Pack.hbs');
var marketingTile2PackTemplate = require('templates/marketingTiles-2Pack.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
    type: 'marketingTiles',
    template: {
        'marketingTiles-4Pack': marketingTile4PackTemplate,
        'marketingTiles-2Pack': marketingTile2PackTemplate
    }
});
